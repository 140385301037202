.add-pointer-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    margin-left: rem-calc(9999);
    opacity: 0;
    transition: opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear .4s;
    $add-pointer-popup: &;

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
    }

    &__title {
        margin-bottom: rem-calc(16);
    }

    &__content {
        position: relative;
        z-index: 2;
        max-width: rem-calc(768);
        width: 100%;
        display: inline-flex;
        transition: all .4s cubic-bezier(.2, 0, 0, 1);
        transform: translateY(30px);
        background-color: map-deep-get($colors, "white", "default");
        padding: 32px;
        border-radius: $border-radius-default;
        opacity: 0;

        &--active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__form {
        width: 100%;
    }

    &__search-input-wrapper {
        position: relative;
        margin-top: rem-calc(40);
    }

    &__search-label {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px 0 24px;
        cursor: pointer;

        svg {
            width: rem-calc(18);
            height: auto;
        }
    }

    &__search-input {
        width: 100%;
        text-indent: rem-calc(40);
        border-radius: rem-calc(20);
    }

    &__results-caption {
        font-size: rem-calc(14);
        margin: rem-calc(16) 0 rem-calc(8);
        font-weight: 700;
        display: block;
    }

    &__results {
        max-height: rem-calc(350);
        border: 1px solid map-deep-get($colors, "gray", "default");
        border-radius: $border-radius-default;
        overflow: auto;
    }

    &__results-item {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__radio-wrapper {
        margin-bottom: 0;
        width: 100%;
    }

    &__form-controls {
        margin-top: rem-calc(16);
        display: flex;
        align-items: center;
    }

    &__label {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__label-image {
        margin-right: rem-calc(20);
        text-align: center;
        width: rem-calc(70);

        /* stylelint-disable */
        img {
            max-width: rem-calc(70);
            max-height: rem-calc(70);
        }
        /* stylelint-enable */
    }

    &__label-amount {
        margin-left: auto;
    }

    &__label-price {
        margin-left: rem-calc(15);
    }

    &__label-amount,
    &__label-price {
        width: rem-calc(75);
    }

    /* stylelint-disable */
    &__label-input {
        position: relative !important;
        left: 0 !important;
    }
    /* stylelint-enable */

    &__label-title {
        font-weight: 700;
    }

    &__delete {
        color: map-deep-get($colors, "validation", "invalid");

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &--active {
        opacity: 1;
        margin-left: 0;
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear;
    }
}
